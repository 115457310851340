<template>
  <v-app>
    <v-app-bar
      app
      dense
      dark
    >
      <div class="d-flex align-center">
       <v-btn
        href="/"
        text
      >
       eyedle.app
     </v-btn>
        <!--
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      -->
      </div>

      <v-spacer></v-spacer>
      <!--
      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Explore Pixels</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
      --> 
      <v-dialog 
        content-class="dialogposition"
        dark
        :value="showDialog"
        width="500"
      >

      <template v-slot:activator="{ on, attrs }">
        <v-btn 
          text
          v-bind="attrs"
          v-on="on"
          @click="setShowDialog({show: true, type:'help'})"
          >
        <v-icon>mdi-help-circle</v-icon>
      </v-btn>

        <v-btn 
          text
          v-bind="attrs"
          v-on="on"
          @click="setShowDialog({show: true, type: 'state' })"
          >
        <v-icon>mdi-poll</v-icon>
      </v-btn>
      </template>

      <v-card >
        <v-card-title class="text-h6 lighten-2">
          {{this.$store.state.dialog.title}} 
          <v-spacer></v-spacer>
          <v-btn icon @click="disableDialog" >
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <span v-html="this.$store.state.dialog.text"></span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <countdown :time="mSecondsToMidnight()" >
            <!-- <template slot-scope="props">Next eyedle:<br> //use this when time mode back.  -->
              <template>Next eyedle: <br>
                
              <v-icon>mdi-infinity</v-icon> mode - unlocked!
              <!--<span class="font-weight-black">
                {{ props.hours }}:<span v-if="props.minutes < 10">0</span>{{ props.minutes }}:<span v-if="props.seconds < 10">0</span>{{ props.seconds }}
              </span>
              -->

              </template>
          </countdown>
          
          <v-spacer></v-spacer>
          
         
          <!--
            v-bind:on-error="alertCopy"
            v-bind:on-success="popSnack(this.messages.copied)"
          -->
          <!-- https://axelhodler.medium.com/web-share-api-in-vue-js-f5b65db80f28 -->
          

            <v-textarea v-if="!webShareApiSupported || $mq=== 'laptop' || $mq === 'desktop' "
            :value="computedMessage" 
            outlined 
            label="click to copy" 
            @click="doCopy"
            >
              
            </v-textarea>
          

            <v-btn v-else
              color="#529E21"
              button
              @click="webShare"
            >
              Share <v-icon>mdi-share-variant</v-icon>
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    </v-app-bar>

    <v-main>  
      <v-snackbar :value="this.$store.state.snackbar.show" 
        top 
        centered 
        light 
        content-class="text-center font-weight-black">
      {{this.$store.state.snackbar.text}}
    </v-snackbar>
      <router-view/>
    </v-main>
  </v-app>
</template>

<style scoped>
  >>> .dialogposition {
    align-self: flex-end;
  }
</style>

<script>
import { mapActions, mapState } from 'vuex';


export default {
  name: 'App',

  data: () => ({
    snackbar: true,
    timeLeft: "12:14:30",
    messages:{
      copied: "📋 Copied to Clipboard",
    } 
  }),
  components:{

  },
  computed: {
    ...mapState(["showDialog"]),
    webShareApiSupported: function() {
      return navigator.canShare
    },
    computedMessage: function(){
      return this.$store.state.endMessage
    }
  },
  methods: {
    ...mapActions([
    'setSnackbar',
    'setShowDialog',
    'disableDialog',
    ]), 
    
    webShare: function(){
     
     var $vm = this

     if( navigator.canShare && navigator.canShare({ files: this.$store.state.shareFiles }) ){
        navigator.share({
          files: this.$store.state.shareFiles,
          title: 'eyedle.app',
          //text: this.$store.state.endMessage,
        })
        .then(() =>   $vm.popSnack("Sharing == Caring") )
        .catch(() => $vm.popSnack("Uh oh!")); //can pass error type

      } else {
        navigator.share({
          title: 'eyedle.app',
          url: 'https://eyedle.app',
          text: this.$store.state.endMessage
        })
        .then(() =>   $vm.popSnack("Sharing == Caring"))
        .catch(() => $vm.popSnack("Uh oh!")); //can pass error type

      }

    },
    doCopy: function(){
      var $vm = this
      //console.log("let's copy")
      //console.log(this.$store.state.endMessage)

       this.$copyText($vm.$store.state.endMessage ).then(function () {
          $vm.popSnack($vm.messages.copied)
          //alert('Copied')
          //console.log(e)
       }, function(){
          //alert('Can not copy')
          $vm.popSnack("Something went wrong while copying. Please manually copy and paste.")
          //console.log(e)
        })

    },
    alertCopy: function(){
        this.$clipboard(this.$store.state.endMessage)
        this.popSnack(this.messages.copied)
    },
    popSnack: function(msg){
      this.setSnackbar( { show:true, text: msg } )
    },
    mSecondsToMidnight: function(){
      const today = new Date()
      const tomorrow = new Date(today)

      tomorrow.setDate(tomorrow.getDate() + 1)
      tomorrow.setHours(0,0,0,0)
     // console.log("today", today)
     // console.log("tomorrow", tomorrow)

      var msTillMidnight = ( tomorrow.getTime() - today.getTime() )
      //console.log("msTillMidnight", msTillMidnight )

      return msTillMidnight;
    }

  }
};
</script>

