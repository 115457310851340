import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    endMessage: "Play the game. No cheating!",
    shareFiles: [],
    showHelp: true,
    showDialog: undefined,
    helpDialog: {
      title: "eyedle.app",
      text: "What do you see?<br><br>Guess a letter correctly and more details of the image will be revealed.<br><br>You get 6 wrong guesses before the game is over."
    },
    dialog: { 
      title: "eyedle.app",
      text: "What do you see?<br><br>Guess a letter correctly and more details of the image will be revealed.<br><br>You get 6 wrong guesses before the game is over."
    },
    snackbar:{
      show: false,
      text: "Looking Good"
    }

  },
  mutations: {
    setMessage (state, payload) {
      state.endMessage = payload.endMessage
    },
    setDialog (state, payload) {
      state.dialog = payload.dialog
    },
    setShareFiles (state, payload) {
      state.shareFiles = payload.shareFiles
    },
    setShowDialog (state, payload){
      //console.log("setShowDialog: state.showDialog: ", state.showDialog)
      if(payload.showDialog.type === 'help'){
        state.dialog = state.helpDialog
      }
      state.showDialog = payload.showDialog.show
    },
    setSnackbar (state, payload){
      state.snackbar = payload.snackbar
    },
    disableDialog (state){
     //console.log('why not work?')
      //console.log("disableDialog: state.showDialog: ", state.showDialog)
      state.showDialog = undefined
    }

  },
  actions: {
    setShareFiles ({ commit }, files) { 
     
      commit('setShareFiles', {
        shareFiles: files
      })
    },
    setMessage ({ commit }, message) {
      
      commit('setMessage', {
        endMessage: message
      })
    },
    setDialog ({ commit }, dialog) {
      
      commit('setDialog', {
        dialog: dialog
      })
    },
    setShowDialog ({commit}, dialog){
      
      commit('setShowDialog', {
        showDialog: dialog
      })
    },
    setSnackbar ({commit}, snackbar){
      
      commit('setSnackbar', {
        snackbar: snackbar
      })
    },
    disableDialog({commit}){
      commit('disableDialog')
    }

  },
  modules: {
  }
})
